import store from 'data/store/store';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getNewArrayExcludingValue } from 'utils/array';
import { useWebAnalytics } from '../../../../webAnalytics';
import { getBookingOffersSearchRoute } from '../../routes';
import { bookingOffersDefaultParams } from '../../utils';
import { bookingOfferListFilterFetch } from '../store/fetch';
import { bookingOfferListSelector } from '../store/selectors';
import { bookingOffersSetCategoryId, bookingOffersSetCompensation, bookingOffersSetFilterPriceRange, bookingOffersSetFilterServices, bookingOffersSetIsNewFetching, bookingOffersSetIsNewSearchFetching, bookingOffersSetPage, bookingOffersSetPageSize, bookingOffersSetSearch, bookingOffersSetSort, bookingOffersStartSession } from '../store/slice';
import { EBookingOfferListRequestType } from '../store/types';
const useBookingOfferListHandlers = props => {
  const {
    guid,
    name,
    categoryId,
    rzdSocialPackage,
    minPrice,
    maxPrice,
    services,
    sort
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    webAnalytics
  } = useWebAnalytics();
  const getCurrentState = useCallback(() => {
    return bookingOfferListSelector(store.getState());
  }, []);
  const applyFiltersToArgs = useCallback(props => {
    history.push(getBookingOffersSearchRoute({
      categoryId,
      minPrice,
      rzdSocialPackage,
      maxPrice,
      services,
      sort,
      guid,
      ...props
    }));
  }, [history, categoryId, minPrice, rzdSocialPackage, maxPrice, services, sort, guid]);
  const onResetFilters = useCallback(() => {
    if (!categoryId) return;
    dispatch(bookingOfferListFilterFetch({
      requestType: EBookingOfferListRequestType.Reset,
      categoryId,
      query: name,
      rzdSocialPackage: false
    }));
    applyFiltersToArgs({
      services: [],
      rzdSocialPackage: false,
      ...bookingOffersDefaultParams.priceRange
    });
    dispatch(bookingOffersSetFilterPriceRange({
      minPrice: null,
      maxPrice: null
    }));
  }, [applyFiltersToArgs, categoryId, dispatch, name]);
  const onChangeSort = useCallback(sort => {
    dispatch(bookingOffersSetSort(sort));
    dispatch(bookingOffersSetIsNewSearchFetching(false));
    applyFiltersToArgs({
      sort
    });
  }, [applyFiltersToArgs, dispatch]);
  const onChangePageSize = useCallback(pageSize => {
    dispatch(bookingOffersSetPageSize(pageSize));
    dispatch(bookingOffersSetIsNewSearchFetching(false));
  }, [dispatch]);
  const onChangePage = useCallback(page => {
    dispatch(bookingOffersSetPage(page));
    dispatch(bookingOffersSetIsNewSearchFetching(false));
  }, [dispatch]);
  const onChangePriceRange = useCallback(priceRange => {
    if (!categoryId) return;
    dispatch(bookingOfferListFilterFetch({
      requestType: EBookingOfferListRequestType.Price,
      categoryId,
      query: name,
      ...priceRange,
      rzdSocialPackage
    }));
    dispatch(bookingOffersSetFilterPriceRange(priceRange));
    dispatch(bookingOffersSetIsNewSearchFetching(false));
    applyFiltersToArgs({
      ...priceRange
    });
  }, [applyFiltersToArgs, categoryId, dispatch, name, rzdSocialPackage]);
  const onChangeCompensation = useCallback(rzdSocialPackageValue => {
    if (!categoryId) return;
    dispatch(bookingOfferListFilterFetch({
      requestType: EBookingOfferListRequestType.Compensation,
      categoryId,
      query: name,
      minPrice,
      maxPrice,
      rzdSocialPackage: rzdSocialPackageValue
    }));
    dispatch(bookingOffersSetCompensation(rzdSocialPackageValue));
    dispatch(bookingOffersSetIsNewSearchFetching(false));
    applyFiltersToArgs({
      rzdSocialPackage: rzdSocialPackageValue
    });
  }, [applyFiltersToArgs, categoryId, dispatch, name, minPrice, maxPrice]);
  const onSelectService = useCallback(id => {
    if (!categoryId) return;
    let actualServices = services ? [...services] : [];
    if (actualServices.includes(id)) {
      actualServices = getNewArrayExcludingValue(actualServices, id);
    } else {
      actualServices.push(id);
    }
    dispatch(bookingOfferListFilterFetch({
      requestType: EBookingOfferListRequestType.Services,
      categoryId,
      query: name,
      services: actualServices,
      minPrice,
      maxPrice,
      rzdSocialPackage
    }));
    dispatch(bookingOffersSetFilterServices(actualServices));
    dispatch(bookingOffersSetIsNewSearchFetching(false));
    applyFiltersToArgs({
      services: actualServices
    });
  }, [applyFiltersToArgs, categoryId, dispatch, maxPrice, minPrice, name, services, rzdSocialPackage]);
  const onChangeCategory = useCallback(category => {
    dispatch(bookingOffersSetIsNewFetching(true));
    dispatch(bookingOffersSetIsNewSearchFetching(false));
    history.push(getBookingOffersSearchRoute({
      name,
      categoryId: category.id,
      guid
    }));
  }, [dispatch, history, guid, name]);
  const onReturnToTopCategory = useCallback(id => {
    dispatch(bookingOffersSetCompensation(false));
    dispatch(bookingOffersSetIsNewFetching(true));
    dispatch(bookingOffersSetIsNewSearchFetching(false));
    history.push(getBookingOffersSearchRoute({
      name,
      categoryId: id,
      guid
    }));
  }, [dispatch, history, guid, name]);
  const onClearSelectedServices = useCallback(() => {
    if (!categoryId) return;
    dispatch(bookingOfferListFilterFetch({
      requestType: EBookingOfferListRequestType.Services,
      categoryId,
      query: name,
      services: []
    }));
    dispatch(bookingOffersSetFilterServices(bookingOffersDefaultParams.services));
    dispatch(bookingOffersSetIsNewSearchFetching(false));
    applyFiltersToArgs({
      services: []
    });
  }, [applyFiltersToArgs, categoryId, dispatch, name]);
  const onShowCard = useCallback(bookingOffer => {
    webAnalytics.offerShowInList(bookingOffer.id);
  }, [webAnalytics]);

  // Установка аргументов
  useEffect(() => {
    const state = getCurrentState();
    const {
      data: {
        sort: currentSort,
        search
      }
    } = state;
    const isGuidChanged = state.guid !== guid;
    const currentName = state.data.search?.name;
    const isNameChanged = (currentName || null) !== (name || null);
    const isCategoryChanged = (search?.categories?.[0] || null) !== (categoryId || null);
    const isSortChanged = sort?.[0] && currentSort && currentSort?.[0] !== sort?.[0];
    const somethingChanged = isGuidChanged || isCategoryChanged || isSortChanged || isNameChanged;
    if (!somethingChanged) {
      return;
    }
    if (isSortChanged) {
      dispatch(bookingOffersSetSort(sort));
    }
    if (isGuidChanged && guid) {
      dispatch(bookingOffersStartSession({
        guid
      }));
      dispatch(bookingOffersSetSearch({
        name: name ?? '',
        categories: categoryId ? [categoryId] : []
      }));
      applyFiltersToArgs({
        guid
      });
    }
    if (isGuidChanged || isCategoryChanged) {
      dispatch(bookingOffersSetIsNewFetching(true));
      dispatch(bookingOffersSetCategoryId(categoryId ?? null));
    } else {
      dispatch(bookingOffersSetIsNewFetching(false));
    }
    if (isNameChanged) {
      dispatch(bookingOffersSetIsNewSearchFetching(true));
    } else {
      dispatch(bookingOffersSetIsNewSearchFetching(false));
    }
  }, [applyFiltersToArgs, categoryId, dispatch, getCurrentState, guid, maxPrice, minPrice, name, services, sort]);
  return {
    onChangeSort,
    onChangePage,
    onChangePageSize,
    onChangePriceRange,
    onSelectService,
    onResetFilters,
    onClearSelectedServices,
    onReturnToTopCategory,
    onChangeCategory,
    onShowCard,
    onChangeCompensation
  };
};
export default useBookingOfferListHandlers;